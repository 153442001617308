export default [
  "wle8300.com",
  "qadencebytqg.com",
  "theqadimgroup.com",
  "accelerant.eu",
  "dsm-firmenich.com",
  "dsm.com",
  "xcelerantconsulting.com",
  "toulamedspa.com",
  "genioo.com",
  "quantumstrat.com",
  "morestrategic.com.au"
];
